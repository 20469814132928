@import url('https://fonts.googleapis.com/css?family=Lato');

.login-failled {
    height: 100vh;
    background-image: url('../../../assets/login/login-web.png');
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100%;
}

.logo-login{
    width: 75%;
    margin-top: 25%;
    margin-left: 10%;
}

.logo-col{
    display: flex;
}

.card-information{
    margin-top: 35%;
    width: 75%;
    border-radius: 10px;

}

.card-body-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}