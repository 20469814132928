div.modalSideBar {
  z-index: 1051 !important;
  width: 340px !important;
}
.modal-backdrop {
  background-color: #395c9d5c !important;
}
.bgArcoprime {
  background: #FFFFFF !important;
  column-gap: 55px;
}
.offcanvas-header {
  padding: 2px 15px 1px 10px;
  border-bottom: 5px solid rgb(29, 112, 182);
  justify-content: end;
}
div.bgArcoprime .logoBlanco {
  margin-left: 8px;
}
.btnSidebar {
  box-shadow: none;
  border-radius: 8px;
  background-color: #1D70B6;
}
@media(min-width: 701px) {
  #itemBar2 {
    display: none!important;
  }
}