@import url('https://fonts.googleapis.com/css?family=Lato');



.btnBackToHome {
    background-color: lightslategray;
    border-radius: 15px;
}

.unauthorizedContainer {
    height: 100vh;
    background: linear-gradient(to bottom right, #EEE, #AAA);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

h1 {
    margin: 40px 0 0px;
}

p {
    color: grey;
}

.lock {
    border-radius: 5px;
    width: 55px;
    height: 45px;
    background-color: #333;
    animation: dip 1s;
    animation-delay: 1.5s;
    position: relative;
}

.lock::before,
.lock::after {
    content: '';
    position: absolute;
    border-left: 5px solid #333;
    height: 50px;
    width: 30px;
}

.lock::before {
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    border: 5px solid #333;
    border-bottom-color: transparent;
    border-radius: 15px 15px 0 0;
    height: 40px;
    animation: lock 2s, spin 2s;
}

.lock::after {
    top: -10px;
    left: 40px;
    transform: translateX(-3px);
    /* border-right: 5px solid transparent; */
    height: 50px;
    width: 5px;
    animation: spin 1.6s;

}

@keyframes lock {
    0% {
        top: -45px;
    }

    65% {
        top: -45px;
    }

    100% {
        top: -30px;
    }
}

@keyframes spin {
    0% {
        transform: scaleX(-1) translate(-50%);
    }

    65% {
        transform: scaleX(1) translate(-50%);
    }

    100% {
        transform: translate(-50%);
    }
}

@keyframes dip {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }

}