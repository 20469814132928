li.linkNavBar:hover {
  background-color: #89daff!important;
}
div.rdt_TableRow:hover {
  background-color: #f0f0f0!important;
}
.rdt_TableHeadRow {
  text-transform: uppercase;
  font-size: 14px;
}
.link-dark, .link-dark:focus {
  color: #fff!important;
}
ul.dropdown-menu {
  padding: 0px;
}
.iconoSession {
  width: 23px;
  height: 23px;
  position: absolute;
  right: 10px;
}
.dropdown-toggle::after {
  vertical-align: -1px;
  margin-left: 8px;
}
.navbar{
  background: #81D4FA!important;
}
.linkNavBar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

nav.navbar {
  height: 69px!important;
}

@media(max-width: 700px) {
  #itemBar {
    display: none!important;
  }
}